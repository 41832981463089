import { mdiMicrosoftExcel } from "@mdi/js";
import Icon from "@mdi/react";
import LetterHead from "@src/assets/images/letterhead/LetterHead-04.png";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import { TreeSelect } from "primereact/treeselect";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Printer, Search } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { ErrorHandler } from "../../../common/utils/Error";
import { downloadCSV, formatCurrency } from "../../../utility/Utils";

export default function AccountStatement() {
  const [data, setData] = useState([]);
  const [journalForm, setJournalForm] = useState(false);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [accounts, setAccounts] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/type/subaccounts"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getJournal = async (account, query) => {
    try {
      const { data, status } = await axios.get(
        `/accountings/statements/account-statement/${account}${query}`
      );

      if (status == 200) {
        let accData = data.data.results;
        let totalDebit = data.data.results
          .filter((r) => r.debit > 0)
          .reduce((prev, acc) => prev + Number(acc.debit), 0);
        let totalCredit = data.data.results
          .filter((r) => r.credit > 0)
          .reduce((prev, acc) => prev + Number(acc.credit), 0);

        accData.push({
          datetime: "",
          memo: "Total",
          debit: totalDebit,
          credit: totalCredit,
          balance: data.data.balance.balance ?? 0,
        });
        setData(accData);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const columns = [
    {
      name: "Date",

      selector: (row) =>
        row.datetime ? moment(row.datetime).format("DD/MM/YYYY") : "",
    },

    {
      name: "Description",

      selector: (row) => row.memo ?? "",
    },
    {
      name: "DR",
      selector: (row) => (row.debit == 0 ? "---" : formatCurrency(row.debit)),
    },
    {
      name: "CR",

      selector: (row) => (row.credit == 0 ? "---" : formatCurrency(row.credit)),
    },
    {
      name: "Balance",

      selector: (row) => formatCurrency(row.balance),
    },
  ];

  const toggleJournalForm = () => {
    setJournalForm(false);
  };

  const defaultValues = {
    start_date: "",
    end_date: "",
    account: "",
    fiscalYear: "",
  };
  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  // useEffect(() => {
  //   getJournal();
  // }, []);
  useEffect(() => {
    Promise.all([getAccounts(), getFiscalYears()]);
  }, []);

  useEffect(() => {
    if (searchParams.get("account")) {
      setValue("account", { value: searchParams.get("account") });
      getJournal(searchParams.get("account"), "");
    }
  }, [searchParams]);

  const onSubmit = (values) => {
    try {
      values.account = values.account?.value;
      values.fiscalYear = values.fiscalYear?.value ?? "";

      let query = "?";

      if (values.start_date) {
        query += `start_date=${values.start_date}`;
      }

      if (values.end_date) {
        query += `${query.length > 1 ? "&" : ""}end_date=${values.end_date}`;
      }

      getJournal(values.account, query);
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Balance Sheet Report`,
    pageStyle: `
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always !important;
      }
      .card{
        box-shadow:none !important;
      }
    }
    
    @page {
      size: auto;
    }
    `,
    bodyClass: "",
    // removeAfterPrint: true,
  });

  const formatTreeNodes = () => {
    let acc = [];
    accounts
      .filter((acc) => acc.type == null)
      .forEach((account) => {
        acc.push({
          key: account._id,
          label: account.name,
          data: account.name,
          selectable: !account.systemAccount,
          children: getSubAccounts(account._id),
        });
      });

    return acc;
  };

  const getSubAccounts = (parent) => {
    return accounts
      .filter((account) => {
        if (account.parentAccount == parent && account.type == parent) {
          return false;
        } else if (account.parentAccount != null && account.type == parent) {
          return false;
        } else if (account.parentAccount == parent || account.type == parent) {
          return true;
        }
      })
      .map((account) => ({
        key: account._id,
        label: account.name,
        data: account.name,
        selectable: !account.systemAccount,
        children: getSubAccounts(account._id),
      }));
  };

  const getFiscalYears = async () => {
    try {
      var { status, data } = await axios.get("/accountings/books/list");
      if (status == 200) {
        setFiscalYears(data?.data ?? []);
        let curr = data.data.find((year) => year.isCurrent == true);
        if (curr) {
          setValue("fiscalYear", { label: curr.name, value: curr._id });
        }
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  return (
    <>
      <Card>
        <CardBody className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Account Statement</h4>
          {/* <ButtonGroup>
            
            <Button size="md" outline>
              <Filter size={15} /> Filter
            </Button>
            
          </ButtonGroup> */}
        </CardBody>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} md={3}>
                <Label className="form-label" for={`fiscal_year`}>
                  Fiscal Year
                </Label>
                <Controller
                  name={`fiscalYear`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      id={`fiscal_year`}
                      className="react-select"
                      classNamePrefix="select"
                      // invalid={errors.lines![index].account && true}
                      {...field}
                      placeholder={"Select Fiscal Year"}
                      options={fiscalYears?.map((year) => ({
                        label: year.name,
                        value: year._id,
                      }))}
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>
              <Col xs={12} md={3}>
                <Label className="form-label" for={`filter_account`}>
                  Account
                </Label>
                <Controller
                  name={`account`}
                  control={control}
                  rules={{ required: "Account is required." }}
                  render={({ field, fieldState }) => (
                    <TreeSelect
                      id={`account_${field.id}`}
                      value={field.value.value}
                      onChange={(val) => {
                        let accName =
                          accounts.find((acc) => acc._id == val.value).name ??
                          "";

                        field.onChange({
                          value: val.value,
                          label: accName,
                        });
                      }}
                      inputRef={field.ref}
                      filter
                      options={formatTreeNodes()}
                      placeholder="Select Item"
                      className={classNames("w-100", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>
              <Col xs={12} md={2}>
                <Label className="form-label" for={`start_date`}>
                  From Date
                </Label>
                <Controller
                  name={`start_date`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={`start_date`}
                      invalid={errors.dateRange && true}
                      {...field}
                      type="date"
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>
              <Col xs={12} md={2}>
                <Label className="form-label" for={`end_date`}>
                  To Date
                </Label>
                <Controller
                  name={`end_date`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={`end_date`}
                      invalid={errors.dateRange && true}
                      {...field}
                      type="date"
                    />
                  )}
                />
                {/* {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )} */}
              </Col>

              <Col xs={12} md={2} className="text-center mt-2">
                <Button className="h-100 w-100" color="primary">
                  {" "}
                  <Search size={14} className="" /> Apply Filter{" "}
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card innerRef={printRef}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div>
              <img src={LetterHead} alt="" className="mb-1" width={70} />
              <h4 className="mb-0">Account Statement</h4>
              <p className="mb-0">All Accounts</p>
              <p className="mb-2">All Date</p>
            </div>
            <div className="no-print">
              <Printer
                size={26}
                className="cursor-pointer me-1"
                title="Print"
                onClick={() => handlePrint()}
              />
              <span
                className="cursor-pointer"
                onClick={() => {
                  let excl = nodes.map((node) => node.data);
                  downloadCSV(excl, "Balance Sheet");
                }}
              >
                <Icon path={mdiMicrosoftExcel} size={1.6} horizontal />
              </span>
            </div>
          </div>

          <DataTable
            noHeader
            responsive
            columns={columns}
            className="react-dataTable"
            data={data}
          />
        </CardBody>
      </Card>
    </>
  );
}
